import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from "@mui/material"
import { Box } from "@mui/system"
import { User } from "models/FMSUsers"
import { useEffect, useState } from "react"
import {
    useGetAvailableAttributesQuery,
    UserValueTypes,
    UserWithIds,
    useUpdateUserMutation,
} from "state/slices/userSlice"
import dayjs from "dayjs"
import FormSelect from "./UserSelect"
import { comboBoxFields } from "./ComboBoxFields"

const foreignLiaison = "Foreign Country Liaison Officer"

function validateCaseToUpdate(_user: User) {
    const fieldsToIgnore = new Set(["startDate", "endDate", "id"])
    if (_user.role?.roleName === foreignLiaison) {
        fieldsToIgnore.add("implementationAgency")
        fieldsToIgnore.add("securityAccessLevel")
    } else if (_user.role?.roleName) {
        fieldsToIgnore.add("country")
    }

    var errors = new Map<string, string>()
    for (const field of fields) {
        if (fieldsToIgnore.has(field)) continue
        if (!_user[field]) {
            errors.set(field, `${field} is required`)
        }
    }
    return errors
}

const fields = [
    // "id",
    "username",
    "role",
    "implementationAgency",
    "securityAccessLevel",
    "country",
    // "startDate",
    // "endDate",
    "status",
]

function getDateValue(date: string | null | undefined) {
    return date ? dayjs(date).format("YYYY-MM-DD") : ""
}

type IProps = {
    user?: User
    close: () => void
}

function UsersForm({ user: userProp, close }: IProps) {
    const [user, setUser] = useState(userProp || ({} as User))
    const [validationErrors, setValidationErrors] = useState<
        Map<string, string>
    >(new Map<string, string>())

    var { data: combofields } = useGetAvailableAttributesQuery(comboBoxFields)
    const [updateUser, { isLoading, isSuccess, isError }] =
        useUpdateUserMutation()

    useEffect(() => {
        if (isSuccess) {
            close()
        }
    }, [isSuccess])

    const handleUpdate = (_user: User) => {
        const validation = validateCaseToUpdate(_user)
        if (validation.size > 0) {
            setValidationErrors(validation)
        } else {
            // Add [field]Id to user object for update
            for (const comboField of comboBoxFields) {
                if (_user[comboField]) {
                    _user[comboField + "Id"] = (_user[comboField] as any).id
                }
            }
            updateUser({
                user: _user as UserWithIds,
                type: user.id ? "update" : "create",
            })
        }
    }

    const handleChangeObj = <T extends keyof User>(
        key: T,
        obj: UserValueTypes<T>
    ) => {
        setUser({ ...user, [key]: obj })
    }

    const showField = (field: string) => {
        if (
            user.role?.roleName === foreignLiaison &&
            (field === "implementationAgency" ||
                field === "securityAccessLevel")
        )
            return false
        if (
            user.role?.roleName &&
            user.role?.roleName !== "Foreign Country Liaison Officer" &&
            field === "country"
        )
            return false
        return true
    }

    const handleChange =
        (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setUser({ ...user, [field]: event.target.value })
        }

    return (
        <>
            <DialogContent dividers>
                <DialogContentText variant="h6" pb={3}>
                    {user.id ? "Update" : "Create"} User
                </DialogContentText>
                <DialogContentText variant="body2" pb={3}>
                    {isLoading && "Loading..."}
                    {isError && "Error!"}
                </DialogContentText>
                <Box display="flex" flexDirection="column" gap={2}>
                    {fields.map((field) => {
                        if (comboBoxFields.includes(field)) {
                            return showField(field) ? (
                                <FormSelect
                                    key={field}
                                    field={field}
                                    obj={user[field]}
                                    handleChange={handleChangeObj}
                                    combofields={combofields?.[field]}
                                    error={validationErrors.has(field)}
                                    helperText={validationErrors.get(field)}
                                />
                            ) : null
                        }
                        return (
                            <TextField
                                key={field}
                                label={field}
                                value={user[field] || ""}
                                onChange={handleChange(field)}
                                error={
                                    validationErrors.size > 0 &&
                                    validationErrors.has(field)
                                }
                                helperText={validationErrors.get(field)}
                                required
                            />
                        )
                    })}
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    onClick={() => handleUpdate(user)}
                    variant="contained"
                >
                    Save
                </Button>
                <Button variant="contained" color="error" onClick={close}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default UsersForm
