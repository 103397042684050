import { Button, Typography } from "@mui/material"
import { VisuallyHiddenInput } from "components/styled"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { ChangeEvent } from "react"

type FileUploadProps = {
  handleFileChange: (file: File) => void
  label: string
}

export default function FileUpload({
  handleFileChange,
  label,
}: FileUploadProps) {
  const fileOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      handleFileChange(files[0])
    }
  }

  return (
    <Button
      component="label"
      variant="outlined"
      startIcon={<CloudUploadIcon />}
    >
      <Typography variant="h6" fontWeight={"bold"}>
        {label}
      </Typography>

      <VisuallyHiddenInput
        type="file"
        name="file"
        onChange={fileOnChangeHandler}
      />
    </Button>
  )
}
