import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AgencyUrl } from "models/Endpoints"
import { User } from "models/FMSUsers"
import { RootState } from "../store"

export interface AuthState {
  groups: AgencyUrl[]
  accessToken: string
  user_name: string
  allowedApis: AgencyUrl[]
  fmsUserInformation?: User
  specialAccessPrograms?: string[]
}

const initialState: AuthState = {
  groups: [],
  accessToken: "",
  user_name: "",
  allowedApis: [],
  fmsUserInformation: undefined,
  specialAccessPrograms: [],
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInformation: (state, action: PayloadAction<User>) => ({
      ...state,
      fmsUserInformation: action.payload,
    }),
    setAuthenticatedUser: (
      state,
      // Can update using any part of AuthState except allowedApis
      action: PayloadAction<Omit<Partial<AuthState>, "allowedApis">>
    ) => {
      if (action.payload.groups) {
        // const allowedApis = action.payload.groups.filter(isApiUrl)
        return {
          ...state,
          ...action.payload,
          allowedApis: action.payload.groups,
        }
      }
      return { ...state, ...action.payload }
    },
  },
  extraReducers: () => {},
})

export const selectAuth = (state: RootState): AuthState => state.auth
export const { setAuthenticatedUser, setUserInformation } = authSlice.actions
export default authSlice
