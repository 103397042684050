export type AuthGroups = "fms-usaf" | "fms-army" | "fms-navy" | "fms-dsca"
export function isAuthGroups(key: string): key is AuthGroups {
  return ["fms-usaf", "fms-army", "fms-navy", "fms-dsca"].includes(key)
}

export const CERBERUS_URL = process.env.REACT_APP_CERBERUS_URL
if (!CERBERUS_URL) throw new Error("CERBERUS_URL not defined")

export interface AgencyUrl {
  name: string
  group: AuthGroups
  url: string
}

const AirForce: AgencyUrl = {
  name: "Air Force",
  group: "fms-usaf",
  url: process.env.REACT_APP_USAF_URL as string,
}

const Navy: AgencyUrl = {
  name: "Navy",
  group: "fms-navy",
  url: process.env.REACT_APP_NAVY_URL as string,
}

const Army: AgencyUrl = {
  name: "Army",
  group: "fms-army",
  url: process.env.REACT_APP_ARMY_URL as string,
}

export function getAgencyUrlFromGroup(
  group: AuthGroups
): AgencyUrl | AgencyUrl[] {
  switch (group) {
    case "fms-usaf":
      return AirForce
    case "fms-navy":
      return Navy
    case "fms-army":
      return Army
    default:
      return [Army, Navy, AirForce]
  }
}
