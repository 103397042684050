import { configureStore } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { TypedUseSelectorHook, useDispatch } from "react-redux"
import api from "./slices/api"
import authSlice from "./slices/authSlice"

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch: () => typeof store.dispatch = useDispatch
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
