import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { SCCase } from "models/FMS"
import api from "./api"
import { RootState } from "../store"
import { AgencyUrl, AuthGroups } from "models/Endpoints"

export type CaseByIDResponse = {
  data: SCCase
  canEdit: boolean
}

const casesSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<SCCase[], AuthGroups>({
      async queryFn(_arg, _queryApi, _extraOptions, _fetchBaseQuery) {
        var { auth } = _queryApi.getState() as RootState

        // Required to make API requests
        if (
          !auth.accessToken ||
          !auth.user_name ||
          !auth.allowedApis
          // !auth.allowedApis.find((group) => Object.values(group).includes(_arg))
        ) {
          return {
            data: [],
          }
        }

        let agencyUrl = auth.allowedApis.find((group) =>
          Object.values(group).includes(_arg)
        )
        if (!agencyUrl) {
          return {
            data: [],
          }
        }

        let queryUrl = `${agencyUrl.url}/case?username=${auth.user_name}`

        // Uses the baseUrl and headers set from the apiSlice
        const res = await _fetchBaseQuery(queryUrl)
        if (res.error) {
          return { error: res.error as FetchBaseQueryError }
        }

        return { data: res.data as SCCase[] }
      },
      // Tags to use when redux invalidates cache
      providesTags: (result) => [
        ...(result?.map(({ id }) => ({ type: "Cases" as const, id })) || []),
        { type: "Cases" as const, id: "LIST" },
      ],
    }),
    getCaseById: builder.query<
      CaseByIDResponse,
      { group: AuthGroups; id: string }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, _fetchBaseQuery) {
        const { auth } = _queryApi.getState() as RootState
        // Required to make API requests
        if (!auth.accessToken || !auth.user_name || !auth.allowedApis) {
          console.log("No auth")
          return {
            data: { data: {} as SCCase, canEdit: false },
          }
        }

        let agencyUrl = auth.allowedApis.find((group) =>
          Object.values(group).includes(_arg.group)
        )
        if (!agencyUrl) {
          console.log("No agency")
          return {
            data: { data: {} as SCCase, canEdit: false },
          }
        }

        var payload: CaseByIDResponse = {
          data: {} as SCCase,
          canEdit: false,
        }

        // First get Edit Permissions
        var queryUrl = `${process.env.REACT_APP_PDP_URL}/authorize/update/userId/${auth.user_name}?ids=${_arg.id}`

        const createPermissionResp = await (
          await fetch(queryUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          })
        ).json()

        for (const jsonObj of createPermissionResp) {
          if (jsonObj.action === "Update" && jsonObj.permit) {
            payload.canEdit = true
          }
        }

        const res = await _fetchBaseQuery(`${agencyUrl.url}/case/id/${_arg.id}`)
        if (res.error) {
          return { error: res.error as FetchBaseQueryError }
        }

        payload.data = res.data as SCCase
        return { data: payload }
      },
      providesTags: (result) => [
        { type: "Cases" as const, id: result?.data.id },
        { type: "Cases" as const, id: "LIST" },
      ],
    }),
    // Case By Id is simpler, don't need to use queryFn
    getCaseByIdd: builder.query<SCCase, { queryParam: AuthGroups; id: string }>(
      {
        query: ({ queryParam, id }) => `${queryParam}/case/id/${id}`,
        providesTags: (result) => [
          { type: "Cases" as const, id: "LIST" },
          { type: "Cases" as const, id: result?.id } || [],
        ],
      }
    ),
    updateCase: builder.mutation<
      SCCase,
      { queryParam: AgencyUrl; scCase: SCCase }
    >({
      query: ({ queryParam, scCase }) => ({
        url: `${queryParam.url}/case`,
        method: "PUT",
        body: scCase,
      }),
      invalidatesTags: (result) => [
        { type: "Cases" as const, id: "LIST" },
        { type: "Cases" as const, id: result?.id } || [],
      ],
    }),
    getAllDSCA: builder.query<SCCase[], string>({
      async queryFn(username, _queryApi, _extraOptions, _fetchBaseQuery) {
        var { auth } = _queryApi.getState() as RootState

        // Required to make API requests
        if (!auth.accessToken || !auth.user_name || !auth.allowedApis) {
          return {
            data: [],
          }
        }

        // Uses the baseUrl and headers set from the apiSlice
        // Use dsca env url
        const res = await _fetchBaseQuery(
          `${
            process.env.REACT_APP_DSCA_URL as string
          }/case?username=${username}`
        )
        if (res.error) {
          return { error: res.error as FetchBaseQueryError }
        }

        return { data: res.data as SCCase[] }
      },
    }),
  }),
})

export const {
  useGetAllQuery,
  useGetCaseByIdQuery,
  useUpdateCaseMutation,
  useGetAllDSCAQuery,
} = casesSlice
