import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import FileUpload from "components/common/FileUpload"
import { CloseButton } from "components/styled"
import { useState } from "react"
import { useSelector } from "react-redux"
import { selectAuth } from "state/slices/authSlice"
import { downloadUploadedFile } from "util/download"
import { DecryptFormValues } from "./types"

type DecryptFormProps = {
  close: () => void
}

export default function DecryptForm({ close }: DecryptFormProps) {
  const [formValues, setFormValues] = useState<DecryptFormValues>({
    selectedFile: undefined,
    isTemplateDocument: false,
  })
  const [fileUploadError, setFileUploadError] = useState("")
  const [isDownloading, setIsDownloading] = useState(false)

  const { fmsUserInformation, specialAccessPrograms } = useSelector(selectAuth)

  const handleFileChange = (file: File) => {
    setFormValues((previousState) => {
      return { ...previousState, selectedFile: file }
    })
    setFileUploadError("")
  }

  const handleSubmit = () => {
    if (!formValues.selectedFile) {
      setFileUploadError("Select a file to decrypt")
      return
    }

    if (fmsUserInformation) {
      const data = `
    {
      "username": "${fmsUserInformation.username}",
      "implementationAgency" : "${fmsUserInformation?.implementationAgency?.agencyName.replace(
        /\s/g,
        ""
      )}",
      "securityAccessLevel" : ${
        fmsUserInformation?.securityAccessLevel?.accessLevelInteger || 0
      },
      "country" : "${fmsUserInformation?.country?.country.replace(/\s/g, "")}",
      "isTemplate": ${formValues.isTemplateDocument},
      "specialAccessPrograms": ${JSON.stringify(specialAccessPrograms || [])}
    }`

      downloadUploadedFile({
        file: formValues.selectedFile,
        data,
        setLoading: setIsDownloading,
      })
    }
  }

  return (
    <>
      <DialogContent>
        <Typography marginBottom={1} variant="h5" fontWeight={"bold"}>
          Upload & Decrypt FMS Case Document
        </Typography>
        <Box
          marginBottom={1}
          display={"flex"}
          flexDirection={"column"}
          rowGap={2}
        >
          <FileUpload
            label="Upload Encrypted Document"
            handleFileChange={handleFileChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!fmsUserInformation?.securityAccessLevel?.id}
                sx={{ textAlign: "left" }}
                onChange={() =>
                  setFormValues((previousState) => {
                    return {
                      ...previousState,
                      isTemplateDocument: !previousState.isTemplateDocument,
                    }
                  })
                }
              />
            }
            label={"Template Document?"}
          />

          {!fileUploadError && (
            <Typography>
              <strong>Selected File: </strong>
              {formValues.selectedFile?.name}
            </Typography>
          )}
          {fileUploadError && (
            <Typography color="error">{fileUploadError}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        {isDownloading && <CircularProgress sx={{ marginX: 3 }} />}
        <Button
          disabled={isDownloading}
          onClick={handleSubmit}
          variant="contained"
        >
          Decrypt Document
        </Button>
        <CloseButton variant="contained" onClick={close}>
          Close
        </CloseButton>
      </DialogActions>
    </>
  )
}
