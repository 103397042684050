import { ApiError, Loading } from "components"
import { useGetAllUsersQuery } from "state/slices/userSlice"
import { Box, Typography } from "@mui/material"
import Users from "components/Tables/Users"
import UsersTable from "components/UsersTable/UsersTable"

// function Admin() {
//     const { data: users, isError, isLoading, isFetching } = useGetAllUsersQuery()
//
//     if (isError) {
//         return <ApiError />
//     }
//     if (isLoading || !users || isFetching) {
//         return (
//             <Box sx={{ margin: "auto", maxWidth: "1500px" }}>
//                 <Loading />
//             </Box>
//         )
//     }
//
//     return (
//         <div>
//             <Users users={users} />
//         </div>
//     )
// }

function Admin() {
  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: "1500px",
        p: "5px",
        pt: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UsersTable />
    </Box>
  )
}

export default Admin
