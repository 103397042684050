import api from "./api"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { User } from "models/FMSUsers"

// Extract the nested objects from User as types
// This way can maintain type safety while
// Having dynamic types for User

// Keys of User
export type UserAttributes = keyof User

// Type of Value from Key
export type UserValueTypes<T extends UserAttributes> = User[T]

// Key-value Set
export type UserAttributeSet = { [T in UserAttributes]: UserValueTypes<T>[] }

type UserAttributeWithID = `${UserAttributes}id`

export type UserWithIds = User & { [T in UserAttributeWithID]: string }
// type UserWithIds = User & { UserAttributes + "id": string }

// JSON object of UserAttributeSet

const userApiURL = process.env.REACT_APP_USERS_URL || ""

const userSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    // <return-type, arg-type>
    getAllUsers: builder.query<User[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, _fetchBaseQuery) {
        let queryUrl = `${userApiURL}/User`

        // Uses the baseUrl and headers set from the apiSlice
        const res = await _fetchBaseQuery(queryUrl)
        if (res.error) {
          return { error: res.error as FetchBaseQueryError }
        }
        const data = res.data as User[]
        return { data }
      },
      // Tags to use when redux invalidates cache
      providesTags: (result) => [
        ...(result?.map(({ id }) => ({ type: "Users" as const, id })) || []),
        { type: "Users" as const, id: "LIST" },
      ],
    }),
    getUserByUsername: builder.query<User, string>({
      async queryFn(_username, _queryApi, _extraOptions, _fetchBaseQuery) {
        let queryUrl = `${userApiURL}/User/username/${_username}`

        const res = await _fetchBaseQuery(queryUrl)
        if (res.error) {
          return { error: res.error as FetchBaseQueryError }
        }
        const data = res.data as User
        return { data }
      },
      // Tags to use when redux invalidates cache
      providesTags: (result) => [
        { type: "Users" as const, id: result?.id },
        { type: "Users" as const, id: "LIST" },
      ],
    }),

    getAvailableAttributes: builder.query<UserAttributeSet, UserAttributes[]>({
      async queryFn(_arg, _queryApi, _extraOptions, _fetchBaseQuery) {
        let queryUrl = (attribute: UserAttributes) =>
          `${userApiURL}/UserLookUps/${attribute}`

        // Call api for each attribute in _arg
        const res = await Promise.all(
          _arg.map((attribute) => _fetchBaseQuery(queryUrl(attribute)))
        )

        // If any of the api calls fail, return the FetchBaseQueryError
        let error = undefined
        res.forEach((r) => {
          if (r.error) error = r.error as FetchBaseQueryError
        })
        if (error) {
          return { error }
        }

        // Map the data from each api call to the corresponding UserAttributes
        var data = {} as UserAttributeSet
        res.forEach((r, idx) => {
          const attribute = _arg[idx]
          data[attribute] = r.data as UserValueTypes<typeof attribute>[]
        })

        return { data }
      },
    }),
    updateUser: builder.mutation<
      User,
      { user: UserWithIds; type: "create" | "update" }
    >({
      query: (req) => ({
        url: `${userApiURL}/User/`,
        method: req.type === "create" ? "POST" : "PUT",
        body: req.user,
      }),
      invalidatesTags: (result) => [
        { type: "Users" as const, id: "LIST" },
        { type: "Users" as const, id: result?.id } || [],
      ],
    }),
  }),
})

export const {
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useGetUserByUsernameQuery,
  useLazyGetUserByUsernameQuery,
  useGetAvailableAttributesQuery,
  useUpdateUserMutation,
} = userSlice
