import styled from "@emotion/styled"
import { Box, Button, Dialog, Tab, Tabs, Typography } from "@mui/material"
import { GridToolbarQuickFilter } from "@mui/x-data-grid"
import { SCCase } from "models/FMS"
import { useState } from "react"
import EncryptForm from "components/Forms/EncryptForm"
import DecryptForm from "components/Forms/DecryptForm"

interface StyledTabProps {
  label: string
}
const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#5777AB",
      color: "white",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "green",
    },
  })
)

type IProps = {
  currentApi: number
  setCurrentApi: (newValue: number) => void
  items: string[]
  data: SCCase[]
}

function GridToolBar({ currentApi, setCurrentApi, items, data }: IProps) {
  const [encryptOpen, setEncryptOpen] = useState(false)
  const [decryptOpen, setDecryptOpen] = useState(false)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentApi(newValue)
  }

  // const toPresentationJson = () =>
  //   data.map((sccase) => ({
  //     title: sccase.title || "",
  //     country: sccase.country.country || "",
  //     dateOfNotification: sccase.dateOfNotification || "",
  //     estimatedCost: sccase.estimatedCost || "",
  //     year: sccase.year || "",
  //     notificationNumber: sccase.notificationNumber || "",
  //     implementationAgency: sccase.implementationAgency.agencyName || "",
  //     securityAccessLevel: sccase.securityAccessLevel.displayName || "",
  //   }))

  return (
    <Box sx={{ display: "flex", mb: 1 /* alignItems: "flex-end" */ }}>
      <Box
        className="table-box"
        sx={{
          flexGrow: 1,
          textAlign: "left",
        }}
      >
        <Typography variant="h5">{items[currentApi]} FMS Cases</Typography>
      </Box>
      <Tabs
        value={currentApi}
        onChange={handleChange}
        aria-label="basic tabs example"
        // Don't use the bottom indicator if using block colored tabs
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {items &&
          items.length > 1 &&
          items.map((item, index) => <StyledTab key={index} label={item} />)}
      </Tabs>

      <Box>
        <Button
          sx={{ mx: 3 }}
          variant="contained"
          onClick={() => setEncryptOpen(true)}
        >
          Encrypt
        </Button>
        <Button
          sx={{ mr: 3 }}
          variant="contained"
          onClick={() => setDecryptOpen(true)}
        >
          Decrypt
        </Button>
        <GridToolbarQuickFilter />
      </Box>
      <Dialog
        open={encryptOpen}
        fullWidth
        maxWidth="md"
        onClose={() => setEncryptOpen(false)}
      >
        <EncryptForm data={data} close={() => setEncryptOpen(false)} />
      </Dialog>

      <Dialog
        open={decryptOpen}
        fullWidth
        maxWidth="md"
        onClose={() => setDecryptOpen(false)}
      >
        <DecryptForm close={() => setDecryptOpen(false)} />
      </Dialog>
    </Box>
  )
}

export default GridToolBar
