import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"
import "App.css"
import { Navbar } from "components"
import { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import config from "config/authconfig"
import AuthProvider from "state/authContext"
import { Routes } from "Routes"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Footer from "components/Footer"
import { Box } from "@mui/material"

const oktaAuth = new OktaAuth(config.oidc)

function App() {
  const history = useHistory()
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false)

  // Okta login functions from documentation
  const triggerLogin = () => {
    history.push("/login")
  }
  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      window.location.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      )
    },
    []
  )
  const authHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      triggerLogin()
    } else {
      setAuthRequiredModalOpen(true)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <Security // OKTA Provider
          oktaAuth={oktaAuth}
          onAuthRequired={authHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <AuthProvider>
            <Box sx={{ position: "relative", minHeight: "100vh" }}>
              <Navbar />
              <Box sx={{ paddingBottom: 10 }}>
                <Routes />
              </Box>
              <Footer />
            </Box>
          </AuthProvider>
        </Security>
      </header>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default App
