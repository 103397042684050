import { LoginCallback, SecureRoute } from "@okta/okta-react"
import "App.css"
import { Auth, NotFound } from "components"
import Admin from "pages/Admin"
import Home from "pages/Home"
import CaseOverview from "pages/SCCase"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"

export function Routes() {
    const history = useHistory()

    return (
        <Switch>
            <Route path="/" exact>
                {/* Reroutes to secured route */}
                <Redirect to="/fmscases" />
            </Route>
            <SecureRoute path="/fmscases" component={Home} />
            <SecureRoute
                path="/caseoverview/:api/:id"
                component={CaseOverview}
            />
            <SecureRoute path="/admin" component={Admin} />
            <Route path="/login" render={() => <Auth.Login />} />
            <Route
                path="/login/callback"
                render={(props) => (
                    <LoginCallback
                        {...props}
                        onAuthResume={() => history.push("/login")}
                    />
                )}
            />
            <Route component={NotFound} />
        </Switch>
    )
}
