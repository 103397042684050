import AlternatingDataGrid from "components/Datagrid/AlternatingDataGrid"
// import { ApiError, Loading } from "components"
import { useGetAllUsersQuery } from "state/slices/userSlice"
import { Box, Button, Dialog, Typography } from "@mui/material"
import { usersColumns } from "./UsersColumns"
import { GridCellParams } from "@mui/x-data-grid"
import { useState } from "react"
import { User } from "models/FMSUsers"
// import UsersForm from "components/Forms/UsersForm"
import UsersForm from "components/Forms/Users/UserForm"

type FormType = "create" | "update"
type FormInput = { type: FormType; user: User }

function UsersTable() {
  const [userToUpdate, setUserToUpdate] = useState<FormInput | null>(null)
  const { data: users, isError, isLoading, isFetching } = useGetAllUsersQuery()

  const handleActionsClicked = (params: GridCellParams) => {
    if (params.colDef.field === "actions")
      setUserToUpdate({ type: "update", user: params.row as User })
  }
  const handleUpdateUser = (user: User) => {
    if (userToUpdate?.type === "update") {
      console.log("update")
    } else {
      console.log("create")
    }
    console.log("user" + user)
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" pr={5} pt={5} pb={0}>
        <Typography variant="h4" component="h1" textAlign="start" pl={2}>
          Users
        </Typography>
        <Button
          variant="contained"
          onClick={() => setUserToUpdate({ type: "create", user: {} as User })}
          size="small"
          sx={{ m: 0.5 }}
        >
          Create User
        </Button>
      </Box>
      <AlternatingDataGrid
        sx={{ mb: 5, mr: 1.5, ml: 1.5, mt: 0 }}
        columns={usersColumns}
        error={isError}
        loading={isLoading || isFetching}
        rows={users || []}
        pageSize={10} // Default Page size
        rowsPerPageOptions={[10]} // Allow changing page size
        disableColumnFilter // Don't allow filtering based on columns
        autoHeight // Adjust height based on data
        paginationMode="client"
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        } // Add row class name for alternating highlights
        onCellClick={(e) => handleActionsClicked(e)}
        getCellClassName={(params) =>
          params.field === "securityAccessLevel"
            ? params.row.securityAccessLevel?.displayName?.toLowerCase() || ""
            : ""
        } // Add cell class name for security access level color
      />

      <Dialog
        open={userToUpdate !== null}
        onClose={() => setUserToUpdate(null)}
        fullWidth
      >
        <UsersForm
          user={userToUpdate?.user}
          close={() => setUserToUpdate(null)}
        />
      </Dialog>
    </Box>
  )
}

export default UsersTable
