import { useOktaAuth } from "@okta/okta-react"
import OktaSignIn, { RenderResultSuccess } from "@okta/okta-signin-widget"
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css"
import React, { useEffect, useRef } from "react"
import config from "../../config/authconfig"
import "./Login.css"

const Login = () => {
  const { oktaAuth } = useOktaAuth()
  const widgetRef = useRef()

  useEffect(() => {
    if (!widgetRef.current) {
      return
    }

    const { pkce, issuer, clientId, redirectUri, useInteractionCode } =
      config.oidc

    const widget = new OktaSignIn({
      baseUrl: issuer.split("/oauth2")[0],
      clientId,
      redirectUri,
      i18n: {
        en: {
          "primaryauth.title": "Sign in to NTT FMS Application",
        },
      },
      authParams: {
        issuer,
        pkce,
      },
      useInteractionCodeFlow: useInteractionCode,
      brandName: "NTT DATA SERVICES",
      logo: require("assets/images/CorporateLogoBlue.png"),
      colors: {
        brand: "#5876aa",
      },
    })

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        // successful login
        const result = res as RenderResultSuccess
        oktaAuth.handleLoginRedirect(result.tokens)
      },
      (err) => {
        console.log("Error: ", err)
        throw err
      }
    )

    return () => widget.remove()
  }, [oktaAuth])

  return (
    <div>
      <div id="okta-auth" ref={widgetRef as React.RefObject<any>} />
    </div>
  )
}

export default Login
