import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useOktaAuth } from "@okta/okta-react"
import { Link } from "@mui/material"
import corporateLogo from "assets/images/CorporateLogotypeTagline_Right_White.png"
import UserAttributes from "./UserAttributes"

function Navbar() {
  const { oktaAuth, authState } = useOktaAuth()
  console.log(authState?.idToken)

  const handleSignOut = () => {
    oktaAuth.signOut().then(() => console.log("signed out"))
  }

  return (
    <Box>
      <AppBar position="static" sx={{ background: "#5777AB" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link href="/" color="inherit" underline="none" width={310}>
            <Box
              display="flex"
              width="100%"
              textAlign="center"
              alignItems="center"
            >
              <img src={corporateLogo} style={{ height: "3rem" }} />
              <Typography variant="h6" component="div">
                FMS Case Tracker
              </Typography>
            </Box>
          </Link>
          <UserAttributes />

          {authState?.isAuthenticated && (
            <Box display="flex" sx={{ width: 310, justifyContent: "flex-end" }}>
              <Button color="inherit" onClick={handleSignOut}>
                SIGN OUT
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Navbar
