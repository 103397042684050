import styled from "@emotion/styled"
import { Button } from "@mui/material"

export const CloseButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "darkgray",
  "&:hover": {
    backgroundColor: "lightgray",
  },
}))
