const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "{clientId}"
const ISSUER =
  process.env.REACT_APP_ISSUER ||
  `https://${process.env.REACT_APP_OKTA_DOMAIN}.com/oauth2/${process.env.REACT_APP_AUTH_SERVER_ID}`
const DISABLE_HTTPS_CHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK === "true" || false
const BASENAME = process.env.REACT_APP_BASENAME || "http://localhost:5173/"
// const REDIRECT_URI = `${window.location.origin}/login/callback`;
const REDIRECT_URI = `${window.location.origin}/implicit/callback`
const USE_INTERACTION_CODE =
  process.env.REACT_APP_USE_INTERACTION_CODE === "true" || false

const oidc = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  disableHttpsCheck: DISABLE_HTTPS_CHECK,
  useInteractionCode: USE_INTERACTION_CODE,
}
const resourceServer = {
  messagesUrl: "http://localhost:8080/api/messages",
}
const app = {
  basename: BASENAME,
}

const config = { oidc, resourceServer, app }
export default config
