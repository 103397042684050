import { TableHead, TableRow, TableCell, Typography } from "@mui/material"

type HeadingProps = { values: string[] }

function TableHeading({ values }: HeadingProps) {
  return (
    <TableHead>
      <TableRow>
        {values.map((val) => (
          <TableCell key={val}>
            <Typography>{val}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeading
