import InfoIcon from "@mui/icons-material/Info"
import { IconButton, Tooltip } from "@mui/material"
import {
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid"
import { SCCase } from "models/FMS"
import { getFullDate, numberToCurrency } from "util/DataConversions"

function getCountry(params: GridValueGetterParams): string {
  return params.row.country?.country || ""
}

function getImplementationAgency(params: GridValueGetterParams): string {
  return params.row.implementationAgency?.agencyName || ""
}

function getSecurityLevel(params: GridValueGetterParams<SCCase>): string {
  return params.row.securityAccessLevel?.displayName || ""
}

function getEstimatedCost(params: GridValueGetterParams<SCCase>): string {
  return numberToCurrency(params.row.estimatedCost || "0")
}

function getDateOfNotification(params: GridValueGetterParams<SCCase>): string {
  return getFullDate(params.row.dateOfNotification || "", params.row.year)
}

function securityAccessValue(access: string): number {
  switch (access.toLowerCase()) {
    case "green":
      return 1
    case "yellow":
      return 2
    case "orange":
      return 3
    case "red":
      return 4
    default:
      return 0
  }
}
const sortAccessLevel: GridComparatorFn<string> = (v1, v2) => {
  return securityAccessValue(v1) - securityAccessValue(v2)
}

const renderedWithToolTip = (params: GridRenderCellParams) => (
  <Tooltip title={params.value || ""}>
    <span>{params.value || ""}</span>
  </Tooltip>
)

const CasesColumns: GridColDef[] = [
  {
    field: "title",
    headerName: "Title",
    headerClassName: "table-header",
    minWidth: 350,
    flex: 1,
    renderCell: renderedWithToolTip,
    sortable: true,
  },
  {
    field: "country",
    headerClassName: "table-header",
    headerName: "Country",
    valueGetter: getCountry,
    width: 120,
    sortable: true,
  },
  {
    field: "dateOfNotification",
    headerClassName: "table-header",
    headerName: "Date",
    valueGetter: getDateOfNotification,
    width: 120,
    sortable: false,
  },
  {
    field: "estimatedCost",
    headerClassName: "table-header",
    headerName: "Estimated Cost",
    valueGetter: getEstimatedCost,
    width: 150,
    sortable: false,
    align: "right",
  },
  {
    field: "year",
    headerName: "Year",
    width: 150,
    sortable: false,
    headerClassName: "table-header",
  },
  {
    field: "notificationNumber",
    headerClassName: "table-header",
    headerName: "Notification Number",
    width: 150,
    sortable: false,
  },
  {
    field: "implementationAgency",
    headerClassName: "table-header",
    headerName: "Implementation Agency",
    width: 170,
    sortable: true,
    valueGetter: getImplementationAgency,
  },
  {
    field: "securityAccessLevel",
    headerName: "Security Level",
    headerClassName: "table-header",
    width: 170,
    sortable: true,
    valueGetter: getSecurityLevel,
    sortComparator: sortAccessLevel,
  },
  {
    field: "actions",
    headerName: "More Details",
    headerClassName: "table-header",
    width: 100,
    // flex: 1,
    sortable: false,
    filterable: false,
    hideable: false,
    align: "center",
    renderCell: () => (
      <IconButton
        size="large"
        edge="start"
        color="primary"
        aria-label="details"
      >
        <InfoIcon />
      </IconButton>
    ),
  },
]

export default CasesColumns
