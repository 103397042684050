import { IconButton, IconButtonProps } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import EditIcon from "@mui/icons-material/Edit"
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { User } from "models/FMSUsers"

function getRole(params: GridValueGetterParams<User>): string {
    return params.row.role?.roleName || ""
}

function getStatus(params: GridValueGetterParams<User>): string {
    return params.row.status?.statusName || ""
}

function getCountry(params: GridValueGetterParams<User>): string {
    return params.row.country?.country || ""
}

function getImplementationAgency(params: GridValueGetterParams<User>): string {
    return params.row.implementationAgency?.agencyName || ""
}

function getSecurityLevel(params: GridValueGetterParams<User>): string {
    return params.row.securityAccessLevel?.displayName || ""
}

export const usersColumns: GridColDef[] = [
    {
        field: "username",
        headerName: "Username",
        headerClassName: "table-header",
        minWidth: 150,
        flex: 1,
        sortable: true,
    },
    {
        field: "role",
        headerName: "Role",
        headerClassName: "table-header",
        minWidth: 250,
        flex: 1,
        sortable: true,
        valueGetter: getRole,
    },
    {
        field: "status",
        headerName: "Status",
        headerClassName: "table-header",
        minWidth: 100,
        flex: 1,
        sortable: true,
        valueGetter: getStatus,
    },
    {
        field: "implementationAgency",
        headerName: "Implementation Agency",
        headerClassName: "table-header",
        minWidth: 250,
        flex: 1,
        sortable: true,
        valueGetter: getImplementationAgency,
    },
    {
        field: "securityLevel",
        headerName: "Security Level",
        headerClassName: "table-header",
        minWidth: 120,
        flex: 1,
        sortable: true,
        valueGetter: getSecurityLevel,
    },
    {
        field: "country",
        headerName: "Country",
        headerClassName: "table-header",
        minWidth: 150,
        flex: 1,
        sortable: true,
        valueGetter: getCountry,
    },
    {
        field: "startDate",
        headerName: "Start Date",
        headerClassName: "table-header",
        minWidth: 100,
        flex: 1,
        sortable: true,
    },
    {
        field: "endDate",
        headerName: "End Date",
        headerClassName: "table-header",
        minWidth: 100,
        flex: 1,
        sortable: true,
    },
    {
        field: "actions",
        headerName: "More Details",
        headerClassName: "table-header",
        width: 100,
        // flex: 1,
        sortable: false,
        filterable: false,
        hideable: false,
        align: "center",
        renderCell: () => (
            <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="details"
            >
                <EditIcon />
            </IconButton>
        ),
    },
]
