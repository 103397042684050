import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material"
import { SCCase } from "models/FMS"
import { getFullDate, numberToCurrency } from "util/DataConversions"
import TableHeading from "./TableHeading"

const columns = [
  "Title",
  "Country",
  "Estimated Cost",
  "Notification Number",
  "Link",
  "Date Of Notification",
  "Implementation Agency",
  "Security Access Level",
  "Year",
]

type IProps = { fmsCase: SCCase }
function CaseOverviewTable({ fmsCase }: IProps) {
  return (
    <>
      {fmsCase && (
        <TableContainer component={Paper} sx={{ mb: 5 }}>
          <Table>
            <TableHeading values={columns} />
            <TableBody>
              <TableRow>
                <TableCell>{fmsCase.title}</TableCell>
                <TableCell>{fmsCase.country.country}</TableCell>
                <TableCell>{numberToCurrency(fmsCase.estimatedCost)}</TableCell>
                <TableCell>{fmsCase.notificationNumber}</TableCell>
                <TableCell>{fmsCase.link}</TableCell>
                <TableCell>
                  {getFullDate(fmsCase.dateOfNotification, fmsCase.year)}
                </TableCell>
                <TableCell>
                  {fmsCase.implementationAgency?.agencyName}
                </TableCell>
                <TableCell
                  style={{
                    background: fmsCase.securityAccessLevel?.displayName,
                  }}
                >
                  {fmsCase.securityAccessLevel?.displayName}
                </TableCell>
                <TableCell>{new Date(fmsCase.year).getFullYear()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default CaseOverviewTable
