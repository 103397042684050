import { Box } from "@mui/material"
import CasesTable from "components/FMSCasesTable/CasesTable"
import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { selectAuth } from "state/slices/authSlice"

function Home() {
    const { user_name } = useSelector(selectAuth)

    if (user_name === "hr_admin") {
        return <Redirect to="/admin" />
    }

    return (
        <Box
            sx={{
                margin: "auto",
                maxWidth: "1500px",
            }}
        >
            <CasesTable />
        </Box>
    )
}

export default Home
