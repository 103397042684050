import { CERBERUS_URL } from "models/Endpoints"
import { SCCase, sccaseToCsv, sccaseToJson } from "models/FMS"
import { toast } from "react-toastify"
import { FileFormatType } from "components/Forms/types"

type DownloadProps = {
  format: FileFormatType
  setLoading: (status: boolean) => void
  reportId: number
  implementationAgencyId: number
  implementationAgencyName: string
  userSecurityClearanceId: number
  userSecurityClearanceLevel: string
  reportContent: SCCase[]
  accessToken: string | undefined
  username: string
  selectedFile?: File
}

type DownloadUploadProps = {
  file: File
  data: string
  setLoading: (status: boolean) => void
}

const getFetchUrl = (format: FileFormatType) => {
  switch (format) {
    case "CSV":
      return "encrypt"
    case "JSON":
      return "encryptbyrow"
    case "DOCX":
      return "docx/encrypt"
    case "XLSX":
      return "excel/encrypt"
    case "DOCXT":
    case "PPTXT":
      return "encryptDocument"
  }
}

export function downloadWithAuthentication({
  reportId,
  implementationAgencyId,
  implementationAgencyName,
  userSecurityClearanceId,
  userSecurityClearanceLevel,
  reportContent,
  accessToken,
  setLoading,
  format,
  username,
  selectedFile,
}: DownloadProps) {
  const fetchType = getFetchUrl(format)
  setLoading(true)

  const headers = new Headers({
    /* Authorization: `Bearer ${accessToken}`*/
  })

  const getRequestBody = (format: FileFormatType) => {
    switch (format) {
      case "CSV":
        headers.append("Content-Type", "application/json")
        return JSON.stringify({
          reportId,
          implementationAgencyId,
          implementationAgencyName,
          userSecurityClearanceId,
          userSecurityClearanceLevel,
          reportContent: sccaseToCsv(reportContent),
        })
      case "DOCXT":
      case "PPTXT":
        const formData = new FormData()
        if (selectedFile) {
          formData.append("file", selectedFile)
          formData.append(
            "data",
            JSON.stringify({
              username,
              securityAccessLevel: userSecurityClearanceId,
              implementationAgency: implementationAgencyName,
            })
          )
        }
        return formData
      default:
        headers.append("Content-Type", "application/json")
        return JSON.stringify({
          username,
          implementationAgency: implementationAgencyName,
          securityAccessLevel: userSecurityClearanceId,
          reportId,
          reportContent: sccaseToJson(reportContent),
        })
    }
  }

  fetch(`${CERBERUS_URL}/cerberus/api/v1/reports/${fetchType}`, {
    method: "POST",
    headers: headers,
    body: getRequestBody(format),
  })
    .then(async (response) => {
      if (!response.ok) {
        let errorMsg = "Error downloading file."

        if (response.status === 400) {
          errorMsg = await response.text()
        }
        throw new Error(errorMsg)
      }
      const fileName = response.headers
        .get("Content-Disposition")
        ?.split("filename=")[1]
      return {
        fileName:
          fileName ||
          `encrypted_file_for_${implementationAgencyName}.${format.toLowerCase()}`,
        content: await response.blob(),
      }
    })
    .then(({ fileName, content }) => {
      // Simulate a download by creating a temporary link and clicking it
      const url = window.URL.createObjectURL(content)
      const a = document.createElement("a")
      a.href = url
      a.download = fileName
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      setLoading(false)
      toast(error.message, { type: "error" })
    })
    .finally(() => setLoading(false))
}

export function downloadUploadedFile({
  file,
  data,
  setLoading,
}: DownloadUploadProps) {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("data", data)

  setLoading(true)

  fetch(`${CERBERUS_URL}/cerberus/api/v1/reports/decrypt`, {
    method: "POST",
    body: formData,
  })
    .then(async (response) => {
      if (!response.ok) {
        let errorMsg = "Error downloading file."

        if (response.status === 400) {
          errorMsg = await response.text()
        }
        throw new Error(errorMsg)
      }
      const fileName = response.headers
        .get("Content-Disposition")
        ?.split("filename=")[1]

      return {
        fileName: fileName || "defaultFileName.docx",
        content: await response.blob(),
      }
    })
    .then(({ fileName, content }) => {
      // Simulate a download by creating a temporary link and clicking it
      const url = window.URL.createObjectURL(content)
      const a = document.createElement("a")
      a.href = url
      a.download = fileName
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      setLoading(false)
      toast(error.message, {
        type: "error",
      })
    })
    .finally(() => setLoading(false))
}
