import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material"
import { Box } from "@mui/system"
import { SCCase } from "models/FMS"
import { useState } from "react"
import { numberToCurrency } from "util/DataConversions"

function validateCaseToUpdate(scCase: SCCase) {
  const fieldsToValidate = ["title", "estimatedCost", "notificationNumber"]
  var errors = new Map<string, string>()
  for (const field of fieldsToValidate) {
    if (!scCase[field]) {
      errors.set(field, `${field} is required`)
    }
  }

  return errors
}

type IProps = {
  scCase: SCCase
  update: (scCase: SCCase) => void
  close: () => void
}
function UpdateForm({ scCase, update, close }: IProps) {
  const [sccCase, setScCase] = useState(scCase)
  // const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [validationErrors, setValidationErrors] = useState<Map<string, string>>(
    new Map<string, string>()
  )

  const handleUpdate = (scCase: SCCase) => {
    const validation = validateCaseToUpdate(scCase)
    if (validation.size > 0) {
      setValidationErrors(validation)
      return
    }
    update(scCase)
  }

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setScCase({ ...sccCase, [field]: event.target.value })
    }

  return (
    <>
      <DialogContent dividers>
        <DialogContentText variant="h6" pb={3}>
          Update Case
        </DialogContentText>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Title"
            value={sccCase.title}
            onChange={handleChange("title")}
            sx={{ pb: 3 }}
            error={validationErrors.size > 0 && validationErrors.has("title")}
            helperText={validationErrors.get("title")}
          />
          <TextField
            label="Country"
            value={sccCase.country.country}
            onChange={handleChange("country")}
            sx={{ pb: 3 }}
            disabled
          />
          <TextField
            label="Estimated Cost"
            value={numberToCurrency(sccCase.estimatedCost)}
            onChange={handleChange("estimatedCost")}
            sx={{ pb: 3 }}
            error={validationErrors.has("estimatedCost")}
            helperText={validationErrors.get("estimatedCost")}
            disabled
          />
          <TextField
            label="Notification Number"
            value={sccCase.notificationNumber}
            onChange={handleChange("notificationNumber")}
            sx={{ pb: 3 }}
            error={validationErrors.has("notificationNumber")}
            helperText={validationErrors.get("notificationNumber")}
            disabled
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          type="submit"
          onClick={() => handleUpdate(sccCase)}
          variant="contained"
        >
          Save
        </Button>
        <Button variant="contained" color="error" onClick={close}>
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default UpdateForm
