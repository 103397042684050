import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  DialogActions,
} from "@mui/material"
import { useOktaAuth } from "@okta/okta-react"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import { useSelector } from "react-redux"
import { selectAuth } from "state/slices/authSlice"
import { useState } from "react"
import { CloseButton } from "./styled/CloseButton"

export default function UserInformation() {
  const { authState } = useOktaAuth()
  const { fmsUserInformation } = useSelector(selectAuth)

  const [userOpen, setUserOpen] = useState(false)

  const userAttributes = [
    {
      label: "Username:",
      value: fmsUserInformation?.username,
    },
    {
      label: "Implementation Agency:",
      value: fmsUserInformation?.implementationAgency?.agencyName,
    },
    {
      label: "Access Level:",
      value: fmsUserInformation?.securityAccessLevel?.displayName,
      style: {
        color:
          fmsUserInformation?.securityAccessLevel?.displayName?.toLowerCase(),
        fontWeight: "bold",
      },
    },
    {
      label: "User Role:",
      value: fmsUserInformation?.role?.roleName,
    },
  ]

  return (
    <>
      {authState?.idToken && (
        <Box
          display={"flex"}
          alignItems={"center"}
          columnGap={1}
          onClick={() => setUserOpen(true)}
        >
          <Typography>
            {<>Welcome {authState?.idToken?.claims.email?.split("@")[0]}</>}
          </Typography>
          <IconButton>
            <AccountBoxIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}

      <Dialog fullWidth open={userOpen} onClose={() => setUserOpen(false)}>
        <DialogTitle columnGap={1} display={"flex"} alignItems={"center"}>
          User Attributes <AccountBoxIcon />
        </DialogTitle>
        <DialogContent>
          <List>
            {userAttributes.map((item, index) => (
              <Box key={`user-attributes-list-container-${index}`}>
                <ListItem>
                  <Typography fontWeight={"bold"}>{item.label}</Typography>{" "}
                  <Typography sx={item.style} marginLeft={1}>
                    {item.value}
                  </Typography>
                </ListItem>
                {index + 1 !== userAttributes.length && (
                  <Divider variant="middle" />
                )}
              </Box>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <CloseButton variant="contained" onClick={() => setUserOpen(false)}>
            Close
          </CloseButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
