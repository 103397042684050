import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  Typography,
} from "@mui/material"
import {
  ApiError,
  CaseOverviewTable,
  CountryTable,
  LineItemTable,
  Loading,
  UpdateForm,
} from "components"
import { AgencyUrl, AuthGroups, getAgencyUrlFromGroup } from "models/Endpoints"
import { SCCase } from "models/FMS"
import { useState } from "react"
import { useParams } from "react-router-dom"
import {
  useGetCaseByIdQuery,
  useUpdateCaseMutation,
} from "state/slices/casesSlice"
import { Link } from "@mui/material"
import { convertSCCaseToUIReadable } from "util/DataConversions"
import GenericTable from "components/Tables/Generic"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

function CaseOverview() {
  const { api, id } = useParams<{ api: AuthGroups; id: string }>()
  const agency = getAgencyUrlFromGroup(api)

  const { data, isError, isLoading, isFetching, error } = useGetCaseByIdQuery({
    group: api,
    id,
  })
  const [updateCase, _ /* updateStatus */] = useUpdateCaseMutation()
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  let sccase: SCCase = data?.data || ({} as SCCase)

  function handleUpdate(scCase: SCCase) {
    if (Object.keys(agency).includes("length")) {
      updateCase({ queryParam: (agency as AgencyUrl[])[0], scCase })
    } else {
      updateCase({ queryParam: agency as AgencyUrl, scCase })
    }
    setShowUpdateModal(false)
  }

  if (isError) {
    return <ApiError error={error as FetchBaseQueryError | undefined} />
  }
  if (isLoading || !data || isFetching) {
    return (
      <Box sx={{ margin: "auto", maxWidth: "1500px" }}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: "1500px",
        p: "5px",
        pt: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Link href="/" sx={{ alignSelf: "start" }}>
        <Typography>View All Cases</Typography>
      </Link>
      <Box display="flex" justifyContent="space-between" pr={5} pt={5}>
        <Typography variant="h4" component="h1" textAlign="start" pl={2}>
          Case Overview
        </Typography>
        <Box>
          {data.canEdit && (
            <Button onClick={() => setShowUpdateModal(true)}>Edit</Button>
          )}
        </Box>
      </Box>
      <CaseOverviewTable fmsCase={sccase} />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Country Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <CountryTable country={data.data.country} /> */}
          <GenericTable
            id={data.data.country.country}
            obj={[data.data.country]}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Order Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LineItemTable lineItems={data.data.lineItems} />
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        fullWidth
      >
        <UpdateForm
          scCase={sccase}
          close={() => setShowUpdateModal(false)}
          update={handleUpdate}
        />
      </Dialog>
    </Box>
  )
}

export default CaseOverview
