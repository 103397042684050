import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material"
import { LineItem } from "models/FMS"
import TableHeading from "./TableHeading"

const columns = ["Amount Purchased", "Stock Item"]

type IProps = { lineItems: LineItem[] }
function LineItemTable({ lineItems }: IProps) {
  return (
    <TableContainer>
      <Table>
        <TableHeading values={columns} />
        <TableBody>
          {lineItems.map((item, idx) => (
            <TableRow key={"lineitem-" + idx}>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.stockItem}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LineItemTable
