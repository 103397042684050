import { Skeleton } from "@mui/material"

function Loading() {
  return (
    <div>
      <Skeleton variant="rounded" sx={{ m: 5, height: 250 }} />
      <Skeleton variant="rounded" sx={{ m: 5, height: 150 }} />
      <Skeleton variant="rounded" sx={{ ml: 5, mr: 5, mt: 5, height: 150 }} />
    </div>
  )
}

export default Loading
