import { AppBar, Typography } from "@mui/material"

export default function Footer() {
  return (
    <AppBar
      position="absolute"
      component="footer"
      sx={{
        bottom: 0,
        top: "auto",
        padding: 1,
        background: "#5777AB",
      }}
    >
      <Typography sx={{ fontSize: 10 }} textAlign={"left"}>
        Copyright © 2024 NTT DATA Federal Services. All rights reserved.
        <br />
        Patent Notice: Attribute Based Encryption (ABE) NTT Research.
        <br />
        Patent Pending: Ser. No. 63/670,692 “Attribute-Based Encryption System
        For Document Security.”
        <br />
        Spill-Proof(tm) Document, Trade Mark Pending
      </Typography>
    </AppBar>
  )
}
