import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../store"

// TODO: Set headers for api token, change base url based on user role
const api = createApi({
    reducerPath: "api",
    tagTypes: ["Cases", "Users"],
    baseQuery: fetchBaseQuery({
        baseUrl: ``,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken
            if (token) {
                headers.set("authorization", `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: (_) => ({}),
})

export default api
