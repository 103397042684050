import { Box } from "@mui/material"
import { GridCellParams } from "@mui/x-data-grid"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { ApiError } from "components"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { selectAuth } from "state/slices/authSlice"
import { useGetAllQuery } from "state/slices/casesSlice"
import AlternatingDataGrid from "../Datagrid/AlternatingDataGrid"
import CasesColumns from "./CasesColumns"
import GridToolBar from "./GridToolBar"

function CasesTable() {
  const history = useHistory()
  const [currentApi, setCurrentApi] = useState(0)
  const { allowedApis, user_name } = useSelector(selectAuth)

  const { isLoading, isError, data, isFetching, error } = useGetAllQuery(
    allowedApis[currentApi]?.group || ""
  )
  const { groups } = useSelector(selectAuth)

  const handleActionsClicked = (params: GridCellParams) => {
    if (params.colDef.field === "actions")
      history.push(
        `/caseoverview/${allowedApis[currentApi].group}/${params.row.id}`
      )
  }

  if (isError) {
    console.log(error)
    return (
      <ApiError
        error={error as FetchBaseQueryError | undefined}
        agency={allowedApis[currentApi].name}
        username={user_name}
      />
    )
  }

  return (
    <Box
      // Add color based on security access level color
      sx={{
        "& .table-header": {
          backgroundColor: "#202640",
          color: "white",
        },
        "& .orange": {
          backgroundColor: "orange",
        },
        "& .yellow": {
          backgroundColor: "yellow",
        },
        "& .red": {
          backgroundColor: "red",
        },
        "& .green": {
          backgroundColor: "green",
        },
      }}
    >
      {isError && <div>{isError}</div>}
      <AlternatingDataGrid
        sx={{ my: 5, mr: 0.5, ml: 0.5 }}
        rows={data || []} // Data to use
        columns={CasesColumns} // Title Columns
        autoHeight // Adjust height based on data
        pageSize={10} // Default Page size
        rowsPerPageOptions={[10]} // Allow changing page size
        disableColumnFilter // Don't allow filtering based on columns
        loading={isLoading || isFetching}
        error={isError}
        components={{ Toolbar: GridToolBar }} // Use a custom toolbar
        componentsProps={{
          toolbar: {
            currentApi,
            setCurrentApi,
            items: groups.map((g) => g.name),
            data: data || [],
          },
        }} // Add props to custom toolbar
        paginationMode="client"
        onCellClick={(e) => handleActionsClicked(e)}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        } // Add row class name for alternating highlights
        getCellClassName={(params) =>
          params.field === "securityAccessLevel"
            ? params.row.securityAccessLevel?.displayName?.toLowerCase() || ""
            : ""
        } // Add cell class name for security access level color
      />
    </Box>
  )
}

export default CasesTable
