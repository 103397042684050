/*
In order to capture authentication variables from token, we need to use a 
provider that wraps our secure routes. This way we can update the redux 
store when authentication completes for any page that exists with our provider. 
Without wrapping in a provider, we'd have to dispatch authentication 
updates on each individual component to ensure that the state has been updated
*/

import { IDToken } from "@okta/okta-auth-js"
import { useOktaAuth } from "@okta/okta-react"
import { AgencyUrl, AuthGroups, getAgencyUrlFromGroup } from "models/Endpoints"
import { useLayoutEffect } from "react"
import { setAuthenticatedUser, setUserInformation } from "./slices/authSlice"
import { useLazyGetUserByUsernameQuery } from "./slices/userSlice"
import { useAppDispatch } from "./store"

type TIDToken = IDToken & {
  claims: {
    groups: string[]
    user_name: string
    specialAccessProgram: string[]
  }
}

function apiUrlFromGroup(group: string) {
  let g = group.toLowerCase()
  return getAgencyUrlFromGroup(g as AuthGroups)
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()
  const oktaAuth = useOktaAuth()
  const [getUserInformation] = useLazyGetUserByUsernameQuery()

  // Use layout effect to ensure that the dispatch is called SYNCRONOUSLY before the
  // component is rendered this way the Redux API slice will have access
  // to the authentication variables
  useLayoutEffect(() => {
    if (oktaAuth?.authState?.isAuthenticated && oktaAuth) {
      const idToken: TIDToken = oktaAuth.authState.idToken as TIDToken

      // TODO: Groups might change depending on DSCA implementation
      const groups = (idToken.claims.groups
        .filter(
          (group) =>
            group.toLowerCase() !== "everyone" &&
            group.toLowerCase() !== "spillproof"
        )
        .map(apiUrlFromGroup)
        .flat()
        .filter((group) => group !== undefined)
        .filter((v, i, a) => a.indexOf(v) == i) || []) as AgencyUrl[]

      getUserInformation(idToken.claims.user_name)
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(setUserInformation(res))
          }
        })
        .catch((err) => {
          console.log(err)
        })

      dispatch(
        setAuthenticatedUser({
          accessToken: oktaAuth.authState.accessToken?.accessToken,
          groups: groups,
          user_name: idToken.claims.user_name,
          specialAccessPrograms: idToken.claims.specialAccessProgram,
        })
      )
    }
  }, [oktaAuth?.authState?.isAuthenticated, oktaAuth?.oktaAuth, oktaAuth])

  return <>{children}</>
}

export default AuthProvider
