import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { CloseButton } from "components/styled/CloseButton"
import { SCCase } from "models/FMS"
import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { selectAuth } from "state/slices/authSlice"
import { useGetAllDSCAQuery } from "state/slices/casesSlice"
import { downloadWithAuthentication } from "util/download"
import FileUpload from "components/common/FileUpload"
import { FileFormatType, EncryptFormValues } from "./types"

type EncryptFormProps = {
  close: () => void
  data: SCCase[]
}

export default function EncryptForm({ data, close }: EncryptFormProps) {
  const [isDownloading, setIsDownloading] = useState(false)
  const { fmsUserInformation, accessToken, groups } = useSelector(selectAuth)

  const allCasesResult = useGetAllDSCAQuery(
    fmsUserInformation?.username || "",
    { skip: !(groups.length > 1) }
  )

  const templateValues = new Set<FileFormatType>(["DOCXT", "PPTXT"])

  const [formValues, setFormValues] = useState<EncryptFormValues>({
    documentFormat: null,
    selectedFile: undefined,
  })

  const [validationErrors, setValidationErrors] = useState("")

  const handleDownload = (format: FileFormatType) => {
    if (fmsUserInformation) {
      downloadWithAuthentication({
        reportContent: allCasesResult.data ? allCasesResult.data : data,
        userSecurityClearanceId:
          fmsUserInformation.securityAccessLevel?.id || 0,
        userSecurityClearanceLevel:
          fmsUserInformation.securityAccessLevel?.displayName,
        implementationAgencyId:
          fmsUserInformation.implementationAgency?.id || 0,
        implementationAgencyName:
          fmsUserInformation.implementationAgency?.agencyName.replace(
            /\s/g,
            ""
          ) || "",
        reportId: 1,
        accessToken: accessToken,
        format,
        setLoading: setIsDownloading,
        username: fmsUserInformation.username,
        selectedFile: formValues.selectedFile,
      })
    } else {
      toast("User information not found", { type: "error" })
    }
  }

  const handleSubmit = () => {
    if (!formValues.documentFormat) {
      setValidationErrors("File Format is required")
      return
    }

    if (
      templateValues.has(formValues.documentFormat) &&
      !formValues.selectedFile
    ) {
      setValidationErrors("File upload required for template based encryption")
      return
    }

    setValidationErrors("")
    handleDownload(formValues.documentFormat)
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormValues((previousState) => {
      return {
        ...previousState,
        documentFormat: event.target.value as FileFormatType,
      }
    })
    setValidationErrors("")
  }

  const handleFileChange = (file: File) => {
    setFormValues((previousState) => {
      return { ...previousState, selectedFile: file }
    })
    setValidationErrors("")
  }

  if (allCasesResult.isError) {
    return <Typography>Error fetching cases. Please try again.</Typography>
  }

  if (allCasesResult.isLoading || allCasesResult.isFetching) {
    return (
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress />
        <Typography sx={{ ml: 3 }}>
          Fetching all cases for DSCA user...
        </Typography>
      </DialogContent>
    )
  }

  const templateOptions = [
    { value: "DOCXT", displayName: "Microsoft Word Template" },
    { value: "PPTXT", displayName: "Microsoft Powerpoint Template" },
  ]

  return (
    <>
      <DialogContent>
        <Typography variant="h5" fontWeight={"bold"}>
          Encrypt & Download FMS Cases
        </Typography>
        <Box margin={2} display={"flex"} flexDirection={"column"} rowGap={2}>
          <FormControl fullWidth>
            <InputLabel>Document format:</InputLabel>
            <Select
              label={"Download Encryption Format"}
              name="fileFormat"
              value={formValues.documentFormat || ""}
              onChange={handleSelectChange}
            >
              <MenuItem value={"DOCX"}>Microsoft Word (.docx)</MenuItem>
              <MenuItem value={"XLSX"}>Microsoft Excel (.xlsx)</MenuItem>
              <MenuItem value={"JSON"}>Row By Row</MenuItem>
              <MenuItem value={"CSV"}>CSV Document</MenuItem>
              {fmsUserInformation?.securityAccessLevel?.id &&
                templateOptions.map((item, index) => (
                  <MenuItem key={`${item}-${index}`} value={item.value}>
                    {item.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {formValues.documentFormat &&
            templateValues.has(formValues.documentFormat) && (
              <>
                <FileUpload
                  label={`Upload .${formValues.documentFormat.substring(
                    0,
                    formValues.documentFormat.length - 1
                  )} File to Encrypt`}
                  handleFileChange={handleFileChange}
                />
                <Typography>
                  <strong>Selected File:</strong>{" "}
                  {formValues.selectedFile?.name}
                </Typography>
              </>
            )}

          <Typography color="error" variant="caption">
            {validationErrors}
          </Typography>
        </Box>

        <DialogActions>
          {isDownloading && <CircularProgress sx={{ marginX: 3 }} />}
          <Button
            disabled={isDownloading}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Encrypt Document
          </Button>
          <CloseButton variant="contained" onClick={close}>
            Close
          </CloseButton>
        </DialogActions>
      </DialogContent>
    </>
  )
}
