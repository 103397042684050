export interface SCCase {
  [index: string]: any
  id: number
  estimatedCost: number | string
  title: string
  dateOfNotification: string
  notificationNumber: string
  link: string
  year: string
  country: Country
  implementationAgency: ImplementationAgency
  securityAccessLevel: SecurityAccessLevel
  lineItems: LineItem[]
}

// Surround with quotes and replace quotes with single quotes
function quote(s: string | number) {
  return '"' + String(s).replace(/"/g, "'") + '"'
}

export function sccaseToDTO(sccase: SCCase[]) {
  return sccase.map((sccase) => {
    const { lineItems, ...caseWithoutLineItem } = sccase
    return {
      ...caseWithoutLineItem,
      caseImplementationAgency: sccase.implementationAgency.agencyName,
      caseSecurityAccessLevel: sccase.securityAccessLevel.accessLevelInteger,
    }
  })
}
// Row by Row json encryption format
export function sccaseToJson(cases: SCCase[]) {
  return cases.map((sccase) => ({
    title: sccase.title || "",
    country: sccase.country.country || "",
    dateOfNotification: sccase.dateOfNotification || "",
    estimatedCost: sccase.estimatedCost || "",
    year: sccase.year || "",
    notificationNumber: sccase.notificationNumber || "",
    implementationAgency: sccase.implementationAgency.agencyName || "",
    securityAccessLevel: sccase.securityAccessLevel.displayName || "",
    caseImplementationAgency: sccase.implementationAgency.agencyName.replace(
      /\s/g,
      ""
    ),
    caseSecurityAccessLevel: sccase.securityAccessLevel.accessLevelInteger,
  }))
}

export function sccaseToCsv(cases: SCCase[]) {
  const table = cases.map((sccase) =>
    [
      sccase.title || "",
      sccase.country.country || "",
      sccase.dateOfNotification || "",
      sccase.estimatedCost || "",
      sccase.year || "",
      sccase.notificationNumber || "",
      sccase.implementationAgency.agencyName || "",
      sccase.securityAccessLevel.displayName || "",
    ]
      .map(quote)
      .join(",")
  )

  table.unshift(
    "title,country,dateOfNotification,estimatedCost,year,notificationNumber,implementationAgency,securityAccessLevel"
  )
  return table.join("\n")
}

export interface Country {
  id: number
  country: string
  customerCode: string
  fmsAecaEligible: boolean
  accelCaseClosureEligible: boolean
  exNumberSuffix: number
  combatantCommand: CombatantCommand
  regionGrouping: RegionGrouping
}

export interface CombatantCommand {
  id: number
  command: string
}

export interface RegionGrouping {
  id: number
  groupingCode: string
}

export interface ImplementationAgency {
  id: number
  agencyName: string
  implementationAgencyCode: string
}

export interface SecurityAccessLevel {
  id: number
  displayCode: string
  displayName: string
  displaySequence: number
  accessLevelInteger: number
}

export interface LineItem {
  id: number
  stockId: number
  quantity: number
  stockItem: string
}
