import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material"
import { UserAttributes, UserValueTypes } from "state/slices/userSlice"
import { comboBoxFields } from "./ComboBoxFields"

const fieldKeyValues = {
    [comboBoxFields[0]]: "roleName",
    [comboBoxFields[1]]: "agencyName",
    [comboBoxFields[2]]: "country",
    [comboBoxFields[3]]: "statusName",
    [comboBoxFields[4]]: "displayName",
}

function getDisplayValueFromObject<T extends UserAttributes>(
    key: T,
    obj: UserValueTypes<T> | undefined
): string {
    if (!obj) return ""
    const k: string = fieldKeyValues[key]
    return obj ? (obj as any)[k] || "" : ""
}

type IFormSelect<T extends UserAttributes> = {
    field: T
    obj: UserValueTypes<T>
    handleChange: (key: T, obj: UserValueTypes<T>) => void
    combofields: UserValueTypes<T>[] | undefined
    error?: boolean
    helperText?: string
}

function FormSelect<T extends UserAttributes>({
    field,
    obj,
    handleChange,
    combofields,
    error,
    helperText,
}: IFormSelect<T>) {
    var itemIndex: string | number = ""
    combofields?.forEach((item, index) => {
        if ((item as any)?.id === (obj as any)?.id) {
            itemIndex = index
        }
    })

    return (
        <FormControl error={error}>
            <InputLabel id={`${field}-label`}>{field + " *"}</InputLabel>
            <Select
                required
                value={`${itemIndex}`}
                onChange={(e) =>
                    handleChange(
                        field,
                        combofields?.[parseInt(e.target.value as string) || 0]
                    )
                }
                label={field}
                labelId={field.toString()}
                renderValue={(value) => {
                    if (value === "-1") return ""
                    return getDisplayValueFromObject(
                        field,
                        (combofields as any)[parseInt(value) || 0]
                    )
                }}
                error={error}
            >
                {combofields?.map((item, index) => (
                    <MenuItem key={index} value={index}>
                        {getDisplayValueFromObject(field, item)}
                    </MenuItem>
                ))}
            </Select>

            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default FormSelect
