import { SCCase } from "models/FMS"

export function getFullDate(
  dateOfNotification: string,
  year: string | number
): string {
  return dateOfNotification + "-" + year
}

export function numberToCurrency(cost: number | string): string {
  let currency: string
  if (typeof cost === "number") {
    currency = cost.toLocaleString()
  } else {
    currency = parseInt(cost).toLocaleString()
  }
  return `$${currency}M`
}

export function convertSCCaseToUIReadable(sccase: SCCase): SCCase {
  var _sccase = Object.assign({}, sccase)
  var estimatedCost: string
  if (typeof _sccase.estimatedCost === "number") {
    estimatedCost = _sccase.estimatedCost.toLocaleString()
  } else {
    estimatedCost = parseInt(_sccase.estimatedCost).toLocaleString()
  }

  _sccase.estimatedCost = `$${estimatedCost}M`
  _sccase.dateOfNotification = sccase.dateOfNotification + "-" + sccase.year

  return _sccase
}
