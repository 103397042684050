import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material"

type IProps = { obj: Array<any>; id: string }
export default function GenericTable({ id, obj }: IProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(obj[0] as {}).map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {obj.map((row) => (
            <TableRow>
              {Object.values(row as {}).map((value, idx) =>
                typeof value === "object" ? (
                  <TableCell key={`row-${id}-${idx}`}>
                    {Object.values(value as {})[0] as string}
                  </TableCell>
                ) : (
                  <TableCell>{value as string}</TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
