import { Box, Typography } from "@mui/material"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

const Error = ({ title, message }: { title: string; message: string }) => (
  <Box
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    marginTop={"10%"}
  >
    <Box sx={{ borderRadius: 6 }} padding={10} bgcolor={"#FCF352"}>
      <Typography variant="h5">{title}</Typography>
      <Typography style={{ whiteSpace: "pre-line" }} variant="h6">
        {message}
      </Typography>
    </Box>
  </Box>
)

type ErrorProps = {
  error?: FetchBaseQueryError
  message?: string
  agency?: string
  username?: string
}
function ApiError({ error, message: _message, agency, username }: ErrorProps) {
  // API PERIMTTER SECURITY ERROR
  let title = "API Error"
  let message = "Could not get requested data. Please try again later."

  if (error === undefined) {
    return <Error title={title} message={_message || message} />
  }
  console.log("error found", error)

  if (error.status === 403 || ((error as any).originalStatus || 0) === 403) {
    title = "API Access Authorization Denied!"
    message = `${
      username ? `The user '${username}' does` : "You do"
    } not have the correct attributes to access this system's APIs.
    The demonstrator will now refactor the ABAC policies to allow access.`
  } else {
    title = `API Error: ${error.status}`

    const msgFromApi = (error.data as any)?.message || (error as any).error
    if (msgFromApi) {
      message = `Message from API: ${msgFromApi}`
    }
  }

  return <Error title={title} message={message} />
}

export default ApiError
